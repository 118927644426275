import React from 'react';
import MoreFormRow, { INPUT_TYPE } from '../fundingType/moreForm/moreFormRow';
import MoreButton from './moreButton';
import styles from '../fundingType/moreForm/styles.module.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import style from './styles.module.css';
const NameForm = ({
  platforms,
  answers,
  setAnswers,
  customClass,
  hideNextBtn,
  hideOtherField,
}) => {
  const isFundingQuestion = platforms[0]?.questionId === 'funding';
  return (
    <div className='container'>
      <div
        className={clsx(
          styles.container,
          isFundingQuestion && styles.fundingQuestions,
          customClass && customClass
        )}
      >
        {platforms.map(({ text, answerId }, index) => (
          <MoreFormRow
            key={index}
            name={text}
            title={text}
            answer={answers && answers[answerId]}
            setAnswers={setAnswers}
            type={answerId}
            answers={answers}
          />
        ))}
        {(platforms[0]?.questionId === 'features' ||
          platforms[0]?.questionId === 'properties' ||  platforms[0]?.questionId === 'revenue_model') &&
          !hideOtherField && (
            <div className={style.otherBox}>
              <div className={style.textHolder}>
                <label htmlFor='Other'>
                  <strong>Other</strong>
                </label>
                <input
                  type='text'
                  name='other'
                  value={answers?.other}
                  placeholder='Enter your answer here...'
                  onChange={({ target: { value } }) => {
                    setAnswers({ ...answers, other: value });
                  }}
                />
              </div>
            </div>
          )}

        {!hideNextBtn && <MoreButton question={platforms} answers={answers} />}
      </div>
    </div>
  );
};

NameForm.propTypes = {
  name: PropTypes.string,
  // question: PropTypes.shape({
  //   id: PropTypes.string.isRequired,
  //   answerIds: PropTypes.object.isRequired,
  // }).isRequired,
};
NameForm.defaultProps = {
  name: null,
};

export default NameForm;
