import clsx from 'clsx';
import React, { useRef } from 'react';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import styles from './styles.module.css';

const Modal = ({
  setIsModalVisible,
  setResetEditor,
  setPostComment,
  title,
  isSignup,
  verifyCode,
  children,
  isCallMe,
}) => {
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeModal);
  return (

    <div className={styles.signupModal}>
      <div className={styles.modalBackdrop} />

      <div
        className={clsx(styles.modalContainer, isCallMe ? styles.mtop10 : '')}
      >
        <div
          className={clsx(styles.content, isCallMe ? styles.callMe : '')}
        >
          <button type="button" className={styles.close} onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className={styles.header}>
            <h6>{title}</h6>
          </div>
          <div className={styles.container}>
            <div className={styles.container}>{children}</div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Modal;
