import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useLocation } from '@reach/router';
import Head from '../../components/Head/Head';
import Header from '../../components/Header/Header';
import FreeFooter from '../../components/common/Footer/FreeFooter/FreeFooter';
import EstimateSection from '../../components/sections/YourEstimate/YourEstimate';
// import Videos from "../../components/sections/Videos/Videos";
import MuchMore from '../../components/sections/MuchMore/MuchMore';
// import Questions from "../../components/sections/Questions/Questions";
import PRICING_ACTIONS from '../../redux/actions/Pricing';
import packPricingObject from '../../functions/pricing/packPricingObject';
import ForumApi from '../../procedures/forum';
import QuestionNumber from '../../components/pricing/question/questionNumber';
import styles from '../../styles/pages.module.css';
import PlatformOptions from '../../layouts/pricing/question/platforms/PlatformOptions';
import QUESTIONS from '../../constants/pricing/questions';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Modal from '../../components/Modal';
import FadeTransition, {
  FADE_DIRECTION,
} from '../../components/common/FadeTransition/FadeTransition';
import Reciept from '../../components/Reciept';

const PricingSignUpPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [modules, setModules] = useState([]);
  const Pricing = useSelector((state) => {
    return state.Pricing;
  });
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [modalContent, setModalContent] = useState({
    bonuses: false,
    breakdown: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [myAnswers, setMyAnswers] = useState({
    features: false,
    prize: false,
    technology: false,
    development: false,
    marketing: false,
  });
  const [showSignup, setShowSignup] = useState(false);

  const [showPricingModal, setShowPricingModal] = useState(false);
  const [title] = useState('Here is your size assessment !');
  const isBrowser = typeof window !== 'undefined';
  const server = isBrowser ? window?.location?.origin : null;
  const {
    config: { estimate },
  } = packPricingObject({ ...Pricing, server });
  const { answers } = Pricing;
  const descriptionComp = () => {
    return `<p>Based on our assessment, your project size is:<br/>
<h3>Project Size: Small (S)</h3>
Scroll down to get your cost and time estimate. 
    </p>`;
  };
  const setBudget = async () => {
    setLoading(true);
    if (estimate) {
      const formatAmount = estimate.split('$')[1].split('.')[0].split(',');
      const action = PRICING_ACTIONS.setEstimateBudget(formatAmount.join(''));
      dispatch(action);
    }
    setLoading(false);
  };

  const getForumData = async (page) => {
    try {
      // setLoadingMedia(true);
      // const res = await ForumApi.getForumData(page);
      // setContent(res?.pageMedia?.assets);
      setDescription(descriptionComp());
      // setTitle(res?.pageMedia?.title);
      // setFid(res?.fid);
      // setModules(res?.modules);
      // setComments(res?.comments);
      // setLoadingMedia(false);
      // dispatch(PRICING_ACTIONS.setForumId(res?.fid));
    } catch (error) {
      setLoadingMedia(false);
      setContent([]);
    }
  };

  useEffect(() => {
    getForumData('sign-up');
    setBudget();
  }, []);

  const dispatchAnswer = (ques, data) => {
    const setAnswer = PRICING_ACTIONS.setAnswer(ques, data);
    dispatch(setAnswer);
  };
  const handleChange = (key) => {
    const answer = answers.get(key);
    let new_answer = { ...answer };
    switch (key) {
      case 'features':
        if (!myAnswers.features) {
          new_answer = { ...answer, other: 'selected on sign-up' };
          dispatchAnswer(key, new_answer);
        } else {
          new_answer = { ...answer, none: true };
          dispatchAnswer(key, new_answer);
        }
        setMyAnswers({ ...myAnswers, features: !myAnswers.features });
        break;
      case 'technology':
        if (!myAnswers.technology) {
          new_answer = 'yes';
          dispatchAnswer(key, new_answer);
        } else {
          new_answer = 'no';
          dispatchAnswer(key, new_answer);
        }
        setMyAnswers({ ...myAnswers, technology: !myAnswers.technology });
        break;
      case 'development':
        if (!myAnswers.development) {
          new_answer = 'yes';
          dispatchAnswer(key, new_answer);
        } else {
          new_answer = 'no';
          dispatchAnswer(key, new_answer);
        }
        setMyAnswers({ ...myAnswers, development: !myAnswers.development });
        break;
      case 'marketing':
        if (!myAnswers.marketing) {
          new_answer = 'yes';
          dispatchAnswer(key, new_answer);
        } else {
          new_answer = 'no';
          dispatchAnswer(key, new_answer);
        }
        setMyAnswers({ ...myAnswers, marketing: !myAnswers.marketing });
        break;
      case 'prize':
        if (!myAnswers.prize) {
          new_answer = 'yes';
          dispatchAnswer(key, new_answer);
        } else {
          new_answer = 'no';
          dispatchAnswer(key, new_answer);
        }
        setMyAnswers({ ...myAnswers, prize: !myAnswers.prize });
        break;
    }
  };
  const getLabelTitle = (key) => {
    switch (key) {
      case 'features':
        return 'Add Custom Features';
        break;
      case 'technology':
        return 'Sample Apps';
        break;
      case 'development':
        return 'Sample Docs';
        break;
      case 'marketing':
        return 'App Success Strategy';
        break;
      case 'prize':
        return 'Get Prize';
        break;
      default:
        return '';
    }
  };
  const getLabelSubtitle = (key) => {
    switch (key) {
      case 'features':
        return (
          <p>
            Improve the accuracy of your quote by considering{' '}
            <b>additional features</b>.
          </p>
        );
        break;
      case 'technology':
        return <p>Get Sample apps to discover and discuss trending ideas.</p>;
        break;
      case 'development':
        return (
          <p>
            Get Sample Docs to discover the process of app development from
            start to finish.
          </p>
        );
        break;
      case 'marketing':
        return (
          <p>
            Get a Plan to succeed with minimum investment at risk.
            <br />
            Learn where people find resources to support app development.
          </p>
        );
      case 'prize':
        return (
          <p>
            Claim your prize to get more benefits.
            <br />
            Learn where people find resources to support app development.
          </p>
        );
      default:
        return '';
    }
  };
  const handleModalContent = (key) => {
    switch (key) {
      case 'price_breakdown':
        setModalContent((prev) => ({
          ...prev,
          breakdown: !prev.breakdown,
          bonuses: false,
        }));
        break;
      case 'bonuses':
        setModalContent((prev) => ({
          ...prev,
          breakdown: false,
          bonuses: !prev.bonuses,
        }));
    }
  };
  const setAnswers = () => {
    let newAnswers = { ...myAnswers };
    Object.keys(myAnswers).forEach((answr) => {
      let current_answr = null;
      switch (answr) {
        case 'features':
          current_answr = answers?.get('features');
          if (
            (current_answr && Object.values(current_answr).includes(true)) ||
            current_answr?.other?.length > 0
          ) {
            newAnswers = { ...newAnswers, features: true };
          }
          break;
        case 'prize':
          current_answr = answers.get('prize');
          if (current_answr && current_answr === 'yes') {
            newAnswers = { ...newAnswers, prize: true };
          }
          break;
        case 'technology':
          current_answr = answers.get('technology');
          if (current_answr && current_answr === 'yes') {
            newAnswers = { ...newAnswers, technology: true };
          }
          break;
        case 'development':
          current_answr = answers.get('development');
          if (current_answr && current_answr === 'yes') {
            newAnswers = { ...newAnswers, development: true };
          }
          break;
        case 'marketing':
          current_answr = answers.get('marketing');
          if (current_answr && current_answr === 'yes') {
            newAnswers = { ...newAnswers, marketing: true };
          }
          break;
        default:
          break;
      }
      setMyAnswers(newAnswers);
    });
  };
  useEffect(() => {
    setAnswers();
  }, []);
  return (
    <>
      <Head />
      <Header pageId={location.pathname} hideLinks />
      <div className='content-holder px-0'>
        <EstimateSection
          // showBudget
          modules={modules}
          showBanner
          setShowSignup={setShowSignup}
          showSignup={showSignup}
          showPricingModal={showPricingModal}
          setShowPricingModal={setShowPricingModal}
          page='sign-up'
          title={title}
          description={description}
        />

        <div className={clsx('container')}>
          <MuchMore
            page='sign-up'
            as='Get Price'
            showSignup={showSignup}
            setShowSignup={setShowSignup}
          />
        </div>
        <div className={clsx('container', styles.goBackHolder)}>
          <Link to='/cost-to-make-an-app/objective' className={styles.goBack}>
            ←&nbsp;&nbsp;Back&nbsp;
          </Link>
        </div>
        {modalContent.breakdown && (
          
          <Modal
            setIsModalVisible={() => {
              setModalContent((prev) => ({
                ...prev,
                breakdown: !prev.breakdown,
                bonuses: false,
              }));
            }}
            title='Sample Estimate'
          >
            <div className={clsx(styles.recieptHolder, 'mb-5')}>
              <Reciept design={100} development={199} type='sample' />
            </div>
          </Modal>
        )}
      </div>
      {/* <FreeFooter /> */}
    </>
  );
};

export default PricingSignUpPage;
